import { mapValues, get, set, isPlainObject, has, fromPairs, isNil } from "lodash";
import {
  Field,
  FieldConfig,
  Form,
  FormConfig,
  PartialFormConfig,
  FieldDescriptorObject,
  FieldDescriptor,
  FieldDataType,
  FieldDisplayType,
  Unit,
  UnitConversionFns
} from "@/types";
import { createConversionFns } from "@/utils/number";
import { descriptorMatchesDimensions, setPath, toDescriptorObject } from "@/utils/indexed-field";
import { DateTime } from "luxon";
import { buildMappedFieldConfigs, getMappedFieldDescriptors, toMappedValues } from "@/utils/mapped-field";

interface FieldOptions {
  deconvertValue?: boolean;
  skipPending?: boolean;
  filterFn?: (item: Field) => boolean;
}

//
// Reading form & field configuration
//

const DEFAULT_FIELD: FieldConfig = {
  dataType: "string",
  displayType: "text",
  dimensions: 0,
  managedState: false,
  dependsOnFields: [],
  labelKey: "label",
  multiple: false,
  readonly: false,
  hideValueUnit: false,
  enum: false,
  disabled: false,
  rules: {},
  mappedfields: {}
};

export const DEFAULT_DISPLAY_TYPE: Record<FieldDataType, FieldDisplayType> = {
  string: "text",
  number: "text",
  boolean: "switch",
  date: "date",
  month_day: "month_day",
  time: "text",
  file: "file"
};

export function readFieldConfig(config: Partial<FieldConfig>): FieldConfig {
  // Select a default display type for the data type if not explicitly set
  const dataType = config.dataType ?? DEFAULT_FIELD.dataType;
  const displayType = config.displayType ?? DEFAULT_DISPLAY_TYPE[dataType];

  return {
    ...DEFAULT_FIELD,
    ...config,
    displayType
  };
}

export function readFormConfig(config: PartialFormConfig): FormConfig {
  const fields = mapValues(config.fields, readFieldConfig);

  return {
    ...config,
    fields
  };
}

export function expandFieldConfig(name: string, fieldConfig: FieldConfig): Record<string, FieldConfig> {
  return {
    [name]: fieldConfig,
    ...buildMappedFieldConfigs(fieldConfig)
  };
}

export function getFieldConfig(formConfig: FormConfig, name: string): FieldConfig {
  const fieldConfig = formConfig.fields[name];
  if (fieldConfig === undefined) throw `Missing field config for ${name}`;
  return fieldConfig;
}

function fullPath(descriptorObj: FieldDescriptorObject): (string | number)[] {
  const { name, params } = descriptorObj;
  return [name, ...params];
}

//
// Working with Field objects
//

export function fieldKey(field: Field): string {
  const { params } = field.descriptor;
  const key = field.config.key ?? field.name;
  const indicesStr = params.map(p => `[${p}]`).join("");
  return `${key}${indicesStr}`;
}

function isFieldObject(fieldObj: Field | any): fieldObj is Field {
  return isPlainObject(fieldObj) && has(fieldObj, "name");
}

export function getFieldObject(
  formConfig: FormConfig,
  model: Form,
  descriptor: FieldDescriptor,
  getFieldOptions: FieldOptions = {}
): Field {
  const descriptorObj = toDescriptorObject(descriptor);
  const path = fullPath(descriptorObj);
  const field = get(model, path);

  if (isFieldObject(field)) {
    return {
      ...field,
      value: getFieldObjectValue(field, getFieldOptions)
    };
  } else {
    const fieldConfig = getFieldConfig(formConfig, descriptorObj.name);
    return buildFieldObject(fieldConfig, descriptorObj, { value: field });
  }
}

export function getExistingFieldObject(
  model: Form,
  descriptor: FieldDescriptor,
  getFieldOptions: FieldOptions = {}
): Field | null {
  const descriptorObj = toDescriptorObject(descriptor);
  const path = fullPath(descriptorObj);
  const field = get(model, path);

  if (!isFieldObject(field)) return null;

  return {
    ...field,
    value: getFieldObjectValue(field, getFieldOptions)
  };
}

function buildFieldObject(
  fieldConfig: FieldConfig,
  descriptor: FieldDescriptorObject,
  fieldData: Partial<Field> = {}
): Field {
  const { dimensions, defaultValue } = fieldConfig;
  if (!descriptorMatchesDimensions(descriptor, dimensions)) {
    throw `Field descriptor (${descriptor.name}, ${descriptor.params.length}) doesn't match field dimensions (${dimensions})`;
  }

  let originalValue = isNil(fieldData.originalValue) && !isNil(defaultValue) ? defaultValue : fieldData.originalValue;
  let value = isNil(fieldData.value) && !isNil(defaultValue) ? defaultValue : fieldData.value;
  const updateInfo = fieldData.updateInfo ?? { status: "normal" };

  const { destUnit, conversionFns } = getFieldConversionDetails(fieldConfig);
  if (conversionFns.convertValueFn) {
    originalValue = conversionFns.convertValueFn(originalValue);
    value = conversionFns.convertValueFn(value);
    if (updateInfo) {
      updateInfo.newValue = conversionFns.convertValueFn(updateInfo.newValue);
    }
  }

  return {
    ...fieldData,
    name: descriptor.name,
    path: fullPath(descriptor),
    descriptor: descriptor,
    originalValue: originalValue ?? null,
    value: value ?? null,
    timestamp: fieldData.timestamp ?? null,
    updateInfo,
    unit: destUnit,
    ...conversionFns,
    config: fieldConfig
  };
}

function getFieldConversionDetails(fieldConfig: FieldConfig): {
  destUnit: Unit | undefined;
  conversionFns: UnitConversionFns;
} {
  const srcUnit = fieldConfig.unit;
  const destUnit = fieldConfig.unitSelectorFn?.() ?? srcUnit;
  const roundValue = fieldConfig.format === "integer";
  const conversionFns = createConversionFns(srcUnit, destUnit, roundValue);
  return { destUnit, conversionFns };
}

function getFieldObjectValue(fieldObj: Field, { deconvertValue }: FieldOptions = {}): any {
  const value = fieldObj.value;
  return deconvertValue && fieldObj.deconvertValueFn ? fieldObj.deconvertValueFn(value) : value;
}

function setFieldObject(model: Form, field: Field, force = true): void {
  if (field.config.managedState) {
    if (force || shouldOverwriteField(field, model)) {
      setPath(model, field.path, field);
    }
  } else {
    set(model, field.path, field.value);
  }
}

function shouldOverwriteField(newField: Field, form: Form): boolean {
  const field = getExistingFieldObject(form, newField.descriptor);
  if (!field) return true;

  const insignificantStatusUpdate =
    newField.updateInfo.status === "normal" || newField.updateInfo.status === field.updateInfo.status;

  if (field.new || field.deleted) {
    if (insignificantStatusUpdate) return false;
  } else if (field.dirty) {
    const insignificantUpdate =
      newField.originalValue === field.originalValue &&
      (newField.updateInfo.newValue === undefined || newField.updateInfo.newValue) === field.updateInfo.newValue &&
      insignificantStatusUpdate;
    if (insignificantUpdate) return false;
  }

  return true;
}

export function setField(
  formConfig: FormConfig,
  model: Form,
  descriptor: FieldDescriptorObject,
  fieldData: Partial<Field> = {},
  force = true
): Field {
  const fieldConfig = getFieldConfig(formConfig, descriptor.name);
  const field = buildFieldObject(fieldConfig, descriptor, fieldData);
  setFieldObject(model, field, force);

  if (fieldConfig.mapsToFields) {
    const { mappingType } = fieldConfig.mapsToFields;
    const fieldValues = {
      originalValue: toMappedValues(mappingType, field.originalValue),
      value: toMappedValues(mappingType, field.value),
      newValue: toMappedValues(mappingType, field.updateInfo.newValue)
    };
    const fieldDescriptors = getMappedFieldDescriptors(fieldConfig, descriptor);
    fieldDescriptors.forEach((mappedDescriptor, fieldIndex) => {
      const mappedFieldConfig = getFieldConfig(formConfig, mappedDescriptor.name);
      const mappedFieldData: Partial<Field> = {
        ...field,
        originalValue: fieldValues.originalValue[fieldIndex],
        value: fieldValues.value[fieldIndex],
        updateInfo: { ...field.updateInfo, newValue: fieldValues.newValue[fieldIndex] }
      };
      const mappedField = buildFieldObject(mappedFieldConfig, mappedDescriptor, mappedFieldData);
      setFieldObject(model, mappedField, force);
    });
  }

  return field;
}

export function addNewField(
  formConfig: FormConfig,
  model: Form,
  descriptor: FieldDescriptorObject,
  fieldData: Partial<Field> = {}
): Field {
  const previousField = getExistingFieldObject(model, descriptor);
  const previousTimestamp = previousField?.deleted ? previousField.timestamp : null;
  const timestamp = previousTimestamp ?? DateTime.fromMillis(0).toISODate();
  const newFieldData = {
    ...fieldData,
    timestamp,
    new: true
  };

  return setField(formConfig, model, descriptor, newFieldData);
}

export function* fieldIndices(formConfig: FormConfig, model: Form, name: string): Generator<number[]> {
  const fieldConfig = getFieldConfig(formConfig, name);
  if (fieldConfig.dimensions === 0) return [];

  yield* fieldIndicesAtLevel(fieldConfig, model, name, []);
}

function* fieldIndicesAtLevel(
  fieldConfig: FieldConfig,
  model: Form,
  name: string,
  currentIndices: number[]
): Generator<number[]> {
  const fieldDimensions = fieldConfig.dimensions;
  if (currentIndices.length < fieldDimensions) {
    const descriptorObj = toDescriptorObject({ name, params: currentIndices });
    const path = fullPath(descriptorObj);
    const collection = get(model, path) ?? [];

    const generatingLastIndex = currentIndices.length === fieldDimensions - 1;

    for (let i = 0; i < collection.length; i++) {
      const newIndices = [...currentIndices, i];
      if (generatingLastIndex) {
        yield newIndices;
      } else {
        yield* fieldIndicesAtLevel(fieldConfig, model, name, newIndices);
      }
    }
  }
}

//
// Form value and state management
//

export function getValue(
  formConfig: FormConfig,
  model: Form,
  descriptor: FieldDescriptor,
  getFieldOptions: FieldOptions = {}
): any {
  return getFieldObject(formConfig, model, descriptor, getFieldOptions).value;
}

export function getValues(
  formConfig: FormConfig,
  model: Form,
  descriptors: FieldDescriptor[],
  getFieldOptions: FieldOptions = {}
): Record<string, any> {
  let fields = descriptors.map(descriptor => getFieldObject(formConfig, model, descriptor, getFieldOptions));

  if (getFieldOptions.filterFn) fields = fields.filter(getFieldOptions.filterFn);
  if (getFieldOptions.skipPending) {
    fields = fields.filter(field => field.updateInfo.status !== "pending");
  }

  const pairs = fields.map(field => [fieldKey(field), field.value]);
  return fromPairs(pairs);
}

// eslint-disable-next-line
export function setValue(formConfig: FormConfig, model: Form, descriptor: FieldDescriptor, value: any): void {
  const fieldObj = getFieldObject(formConfig, model, descriptor);
  const dirty = value !== fieldObj.originalValue;
  setFieldObject(model, { ...fieldObj, value, dirty });
}

export function setDeleted(formConfig: FormConfig, model: Form, descriptor: FieldDescriptor): void {
  const field = getFieldObject(formConfig, model, descriptor);
  const allFields = [field, ...getMappedFields(formConfig, model, field)];

  allFields.forEach(field => {
    setFieldObject(model, { ...field, deleted: true, new: false, value: null });
  });
}

function getMappedFields(formConfig: FormConfig, model: Form, field: Field): Field[] {
  const { config, descriptor } = field;
  if (!config.mapsToFields) return [];

  const fieldDescriptors = getMappedFieldDescriptors(config, descriptor);
  return fieldDescriptors.map(d => getFieldObject(formConfig, model, d));
}
