import { DateTime, Duration } from "luxon";
import { Ref } from "vue";
import { ValidationFlags } from "vee-validate/dist/types/types";

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends (...args: any) => any
    ? T[P] | undefined
    : T[P] extends Record<string, unknown>
    ? RecursivePartial<T[P]>
    : T[P];
};

export type MaybeRef<T> = T | Ref<T>;

export enum MetricIndicatorType {
  Plain = "plain",
  Nominal = "nominal",
  Warning = "warning",
  Critical = "critical",
  Stale = "stale"
}

export enum SettingType {
  Active,
  EnabledActive,
  Value
}

export enum LocationType {
  Building = "BUILDING",
  Asset = "ASSET",
  Model = "MODEL",
  KnownAsset = "KNOWN_ASSET"
}

export enum DocumentType {
  Document = "DOCUMENT",
  Video = "VIDEO"
}

export type FieldDataType = "string" | "number" | "boolean" | "date" | "month_day" | "time" | "file";

export type FieldDisplayType = "text" | "switch" | "date" | "month_day" | "select" | "combobox" | "file";

export type FieldMappingType = "datetime_to_month_day_time" | "datetime_to_date_time";

export type Unit =
  | "degrees_c"
  | "degrees_f"
  | "degrees_c_relative"
  | "degrees_f_relative"
  | "pct"
  | "ppm"
  | "ppb"
  | "mcg_m3"
  | "tvoc_level"
  | "lux"
  | "kwh"
  | "cfm"
  | "mins"
  | "milliamps"
  | "amps"
  | "kw"
  | "volts"
  | "people";

export type UnitOrDefault = Unit | "default_unit";

export type Aggregation = "AVERAGE" | "MAXIMUM";
export type Format = "integer" | "decimal1dd" | "decimal2d" | "decimal2dd" | "boolean_yes_no";

export type PropertyState =
  | "DOWNLINKED"
  | "DOWNLINK_CONFIRMED"
  | "UPLINK_MATCHED"
  | "CONFIRM_TIMEOUT"
  | "UPLINK_TIMEOUT"
  | "UPLINK_MISMATCH"
  | "REFRESHING"
  | "ERROR";

export type ValidationRules = Record<string, any>;

export interface PropFnArgs {
  model: Form;
  params: FieldDescriptorParams;
}

type PropValueOrFn<T> = T | ((args: PropFnArgs) => T);

export type OptionsProp = PropValueOrFn<string[]>;
export type ParamsSelectorProp = PropValueOrFn<FieldDescriptorParams>;
export type DisabledProp = PropValueOrFn<boolean>;
export type ValidationRulesProp = PropValueOrFn<ValidationRules>;
export type RangeFn = (args: PropFnArgs) => { min: number; max: number };

type UnitSelectionFields = "unit" | "unitSelectorFn" | "altUnits";
type UnitSpecificConfig<T> = { [key in Unit]?: Omit<Partial<T>, UnitSelectionFields> };

export interface FieldConfig {
  dataType: FieldDataType;
  displayType: FieldDisplayType;
  key?: string;
  dimensions: number;
  i18nNamespace?: string;
  managedState: boolean;
  dependsOnFields: FieldDescriptor[];
  labelKey: string;
  defaultValue?: any;
  options?: OptionsProp;
  multiple: boolean;
  readonly: boolean;
  format?: Format;
  unit?: Unit;
  hideValueUnit: boolean;
  unitSelectorFn?: () => Unit;
  altUnits?: UnitSpecificConfig<FieldConfig>;
  enum: boolean;
  paramsSelectorFn?: ParamsSelectorProp;
  disabled: DisabledProp;
  rules: ValidationRulesProp;
  mapsToFields?: PropertyFieldMapping;
  mappedfields: Record<string, Partial<FieldConfig>>;
}

export interface FormConfig {
  i18nNamespace: string;
  fields: Record<string, FieldConfig>;
}

export interface PartialFormConfig extends Omit<FormConfig, "fields"> {
  fields: Record<string, Partial<FieldConfig>>;
}

export type ParamProvider = (param: string) => number | undefined;
export type BaseUnitConversionFn = (value: number, params?: ParamProvider) => number;
export type UnitConversionFn = (value: number | null, params?: ParamProvider) => number | null;

export interface UnitConversionFns {
  convertValueFn?: UnitConversionFn;
  deconvertValueFn?: UnitConversionFn;
}

export interface TimestampField {
  timestamp: string | null;
}

export type FieldUpdateStatus = "normal" | "pending" | "success" | "failure";

export interface FieldUpdateInfo {
  status: FieldUpdateStatus;
  message?: string;
  newValue?: any;
}

export interface Field extends UnitConversionFns, TimestampField {
  name: string;
  path: (string | number)[];
  descriptor: FieldDescriptorObject;
  originalValue: any;
  value: any;
  updateInfo: FieldUpdateInfo;
  config: FieldConfig;
  unit?: Unit;
  new?: boolean;
  deleted?: boolean;
  dirty?: boolean;
}

export interface FieldChange {
  descriptor: FieldDescriptorObject;
  value: any;
}

export interface FormState {
  model: Form;
  config: FormConfig;
  handleFieldChange: (change: FieldChange) => void;
  onlyEmitWhenValid: boolean;
  validateImmediately: boolean;
  submitting: boolean;
  locked: boolean;
}

export type Form = Record<string, any>;

export interface GraphSeriesConfig {
  graphFormat?: Format;
  min?: number;
  max?: number;
  comparable: boolean;
  aggregation: Aggregation;
  tickAmount?: number;
  tickInterval?: number;
  stepGraph: boolean;
  fitBounds: boolean;
}

export interface PropertyConfig extends GraphSeriesConfig, UnitConversionFns {
  dataType: FieldDataType;
  category?: string;
  key?: string;
  dimensions: number;
  i18nNamespace: string;
  dependsOnFields: FieldDescriptor[];
  labelKey: string;
  defaultValue?: any;
  format?: Format;
  unit?: Unit;
  hideValueUnit: boolean;
  unitSelectorFn?: () => Unit;
  altUnits?: UnitSpecificConfig<PropertyConfig>;
  paramsSelectorFn?: ParamsSelectorProp;
  fieldConfig: Partial<FieldConfig>;
}

export interface PropertyFieldMapping {
  mappingType: FieldMappingType;
  fieldNames: string[];
}

export type AssetComponentFn = () => Promise<{ default: any }>;

export interface AssetComponentConfig {
  component: AssetComponentFn;
  props?: Record<string, any>;
}

export interface PropertyMapping {
  property: string;
  unit?: Unit;
}

export interface AssetConfig {
  i18nNamespace: string;
  fields: Record<string, FieldConfig>;
  image?: any;
  diagramComponent?: any;
  components: Record<string, AssetComponentFn | AssetComponentConfig>;
  staleDataDuration: number;
  categoryProperties: Record<string, PropertyMapping>;
  properties: Record<string, PropertyConfig>;
  pathMap: Record<string, string>;
}

export interface PartialAssetConfig extends Omit<Partial<AssetConfig>, "fields" | "properties"> {
  fields?: Record<string, Partial<FieldConfig>>;
  properties?: Record<string, Partial<PropertyConfig>>;
}

export interface KnownAsset {
  name: string;
  category: string;
  type: string;
  subtype?: string;
  knownAssetUuid: string;
  config: { default: AssetConfig };
}

export type KnownAssetPredicate = Partial<KnownAsset> | ((ka: KnownAsset) => boolean);

export interface SelectOption {
  text: string;
  value: any;
}

export interface ComboboxValue {
  id: string | null;
  text: string | null;
}

export interface HeatmapDimensions {
  containerHeight: number;
  minContainerWidth: number;
  borderRadius: number;
}

export type ThresholdField = "lowCritical" | "lowWarning" | "highWarning" | "highCritical";

export type Thresholds = {
  [Property in ThresholdField]: number | null;
};

export interface LatLng {
  lat: number | null;
  lng: number | null;
}

export interface Marker {
  uuid: string;
  position: LatLng;
  title: string;
  icon: string;
  to?: Record<string, any>;
}

export interface Floorplan {
  url: string;
  sizeX: number;
  sizeY: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Floor {}

export interface AssetPropertyHealth {
  propertyName: string;
  normalTimePct: number;
}

export interface ParentLink {
  linkType: string;
  parentAsset: GqlAsset;
}

export interface ChildLink {
  linkType: string;
  childAsset: GqlAsset;
}

export interface UserProfile {
  user: User;
  userUuid: string;
  contactInfoUuid: string;
  contactInfo?: Contact;
}

export interface User {
  userUuid: string;
  emailAddress: string;
  activated: boolean;
  contactInfo?: Contact;
}

export interface UsergroupAccessInfo {
  usergroupUuid: string;
  name: string;
  ownerUuid: string;
  singleUser: boolean;
  users: [User];
  role: string;
}

export interface Contact {
  contactInfoUuid: string;
  accountUuid: string;
  emailAddress: string;
  emailEnable: boolean;
  name: string;
  ownerUuid: string;
  phoneNumber: string;
  smsEnable: boolean;
  isUserContactInfo: boolean;
}

export interface AlertConfig {
  alertConfigUuid: string;
  alertName?: string;
  parameterName: string;
  timeout?: number;
  lastAlert?: DateTime;
  timeoutOverride?: DateTime;
  enabled: boolean;
  recipients: AlertRecipient[];
  ownerUuid: string;
  buildingUuid: string;
  building?: Record<string, any>;
  assetUuid: string;
  thresholds?: Thresholds;
}

export interface AlertRecipient {
  alertRecipientUuid: string;
  contactInfoUuid: string;
  contactInfo: Contact;
  enable: boolean;
}

export interface Comment {
  commentUuid: string;
  userUuid: string;
  stamp: DateTime;
  updated: DateTime;
  comment: string;
  user: User;
}

export interface CommentPage {
  data: Comment[];
  pageInfo: PageInfo;
}

export interface GenericEvent {
  eventUuid: string;
  accountUuid?: string;
  buildingUuid?: string;
  building?: Record<string, any>;
  floorUuid?: string;
  floor?: Record<string, any>;
  assetUuid?: string;
  asset?: Record<string, any>;
  ownerUuid?: string;
  stamp: DateTime;
  eventType: string;
  eventData: Record<string, any>;
  user?: Record<string, any>;
  comments?: CommentPage;
}

export interface EventPage {
  data: GenericEvent[];
  pageInfo: PageInfo;
}

export interface PageInfo {
  totalCount: number;
  pageCount: number;
  limit: number;
  offset: number;
}

export interface DeviceLock {
  expiration?: string | null;
  lockOwnerUuid?: string | null;
  lockOwner?: User | null;
}

export interface Asset {
  assetUuid: string;
  knownAssetUuid: string;
  building?: GqlBuilding;
  buildingUuid?: string;
  floorUuid?: string | null;
  floor?: Floor;
  assetCategory?: GqlCategory | null;
  assetType?: GqlAssetType | null;
  manufacturer?: GqlManufacturer | null;
  assetModel?: GqlModel | null;
  assetCategoryUuid: string | null;
  assetTypeUuid: string | null;
  manufacturerUuid: string | null;
  assetModelUuid: string | null;
  name: string;
  serialNumber: string;
  floorX: number | null;
  floorY: number | null;
  floorRealX: number | null;
  floorRealY: number | null;
  smart: boolean;
  online: boolean;
  installationDate: DateTime | null;
  assetHealth?: AssetPropertyHealth[];
  parentLinks?: ParentLink[];
  childLinks?: ChildLink[];
  deviceLock?: DeviceLock;
}

export interface FloorFields {
  name: string;
}

export interface Floor extends FloorFields {
  floorUuid: string;
  position: number;
  floorplan: Floorplan | null;
  assets: Asset[];
}

export interface DocumentUploadDetails {
  name: string;
  fileObject: File;
  location: string;
  locationId: string;
}

export interface DocumentFields {
  name: string;
  uuid: string;
}

export interface Document extends DocumentFields {
  ownerId: string;
  uploadDate: string;
  locationId?: string;
  locationType: string;
  modificationDate: string;
  mimeType: string;
  originalExtension: string;
  url: string;
}

export interface FloorplanFields {
  image: File | null;
  sizeX: number | null;
  sizeY: number | null;
}

export interface BuildingFields {
  name: string;
  address: string;
  timeZone: string;
  lat: number | null;
  lng: number | null;
}

export interface GroupFields {
  name: string;
}

export interface ContactFields {
  name: string;
  emailAddress: string | null;
  phoneNumber: string | null;
  smsEnable: boolean;
  emailEnable: boolean;
}

export interface EnergyDatum {
  kwhEst: number | null;
  kwhActual: number | null;
  time: string;
}

export interface AggEnergyDatum {
  kwhActual: number | null;
  kwhEst: number | null;
  time: string;
}

export interface EnergyConsumption {
  points: EnergyDatum[];
}

export interface EnergyDevice {
  name: string | null;
  kwhTotal: number | null;
}

export interface AggEnergyConsumption {
  kwhTotal: number | null;
  kwhAvgDaily: number | null;
  points: AggEnergyDatum[];
  energyDevices: EnergyDevice[];
}

export interface ActualEstimatedSeriesDatum {
  time: DateTime;
  actual: number | null;
  estimated: number | null;
}

export type ThresholdParent = "UBX_ACCOUNT" | "UBX_BUILDING_GROUP" | "UBX_BUILDING" | "UBX_ASSET";

export interface ThresholdPair {
  violationLevel: number;
  compareValue: number;
}

export interface ThresholdFields {
  thresholdArray: ThresholdPair[];
  thresholds: Thresholds;
}

export interface DecoratedProperty<T = any> extends Omit<GqlProperty<T>, "stamp">, ThresholdFields, TimestampField {
  name: string;
  params: FieldDescriptorParams;
  config: PropertyConfig;
}

export type PropertyOrName = DecoratedProperty | string;

export type GqlTimeSeriesDatum = string | number | null;
export type GqlTimeSeries = [string, GqlTimeSeriesDatum][];
export type TimeSeries = [number, number | null][];

export interface AssetPropertySelection {
  asset: DecoratedAsset;
  property: DecoratedProperty;
}

export interface LineGraphSeries {
  series: TimeSeries;
  loading: boolean;
  entityName: string;
  propertyName: string;
  buildingName?: string;
}

export interface GqlAccount {
  accountUuid: string;
  name: string;
  groups: GqlGroup[];
}

export interface GqlGroup {
  groupUuid: string;
  name: string;
  buildings: GqlBuilding[];
}

export interface GqlBuilding {
  buildingUuid: string;
  name: string;
  fullAddress: string;
  online: boolean;
  timeZone: string;
  gpsCoordinates?: LatLng;
}

export interface GqlAssetType {
  assetTypeUuid: string;
  name: string;
}

export interface GqlCategory {
  assetCategoryUuid: string;
  name: string;
  assetTypes: GqlAssetType[];
}

export interface GqlManufacturer {
  manufacturerUuid: string;
  name: string;
}

export interface GqlModel {
  assetModelUuid: string;
  name: string;
}

export interface GqlStateInfoPopulated<T = any> {
  // Will be omitted when state is REFRESHING
  new_value?: T;
  old_value?: T;
  request_stamp: string;
  request_uuid: string;
  timeout: string | null;
}

export type GqlStateInfo<T = any> = Partial<GqlStateInfoPopulated<T>>;

// TODO Once state management is live, this should be merged into GqlProperty
export interface GqlPropertyStateAdditions<T = any> {
  update_stamp: string | null;
  state: PropertyState | null;
  pending: boolean;
  state_info: GqlStateInfo<T>;
}

export type GqlProperty<T = any> = {
  value: T;
  stamp: string | null;
} & GqlPropertyStateAdditions<T>;

export interface GqlAsset extends Omit<Asset, "installationDate"> {
  installationDate: string | null;
  properties: Record<string, GqlProperty>;
  settings: Record<string, GqlProperty>;
  miscFields: Record<string, GqlProperty>;
  thresholds: { values: Record<string, ThresholdPair[]> };
}

export interface GqlAssetDataChange<T = any> {
  assetUuid: string;
  deviceLock: boolean | null;
  property: string;
  value: T;
  stamp: string | null;
  updateStamp: string | null;
  state: PropertyState | null;
  pending: boolean;
  stateInfo: GqlStateInfo<T>;
}

export type FieldArray = Field[];
export type FieldMap = Record<string, Field | FieldArray>;

export type IndexedField<T> = T | IndexedField<T>[];
export type IndexedFieldMap<T> = Record<string, IndexedField<T>>;
export type PropertyItem = IndexedField<DecoratedProperty>;

export interface DecoratedAsset extends Asset {
  properties: IndexedFieldMap<DecoratedProperty>;
  thresholds: Record<string, ThresholdPair[]>;
  config: AssetConfig;
  knownAsset?: KnownAsset;
  locked: boolean;
}

export interface DateRangeFields {
  startDate?: DateTime;
  endDate?: DateTime;
}

export interface GqlMutationResult {
  message: string;
  stamp: string;
  statusCode: number;
}

export interface DateTimeParts {
  date: DateTime;
  time: string;
}

export interface MonthDayTime {
  month: number;
  day: number;
  time: string;
}

export interface PropertyEnterEvent {
  timestampDate: DateTime;
  relativeTimestamp: Duration;
  x: number;
  y: number;
}

// for building alert config hierarchy
export type PropertyNode = {
  propertyName: string;
  alertConfigs: AlertConfig[];
};

export type AssetNode = {
  assetUuid: string;
  properties: PropertyNode[];
};

export type BuildingNode = {
  buildingName: string;
  assets: AssetNode[];
};

export type AlertConfigTree = {
  buildings: BuildingNode[];
};

export type FieldDescriptorParams = number[];

export interface FieldDescriptorObject {
  name: string;
  params: FieldDescriptorParams;
}

export type FieldDescriptor = string | FieldDescriptorObject;

export type FormValues = Record<string, any>;

export interface SubmitSelection {
  descriptors: FieldDescriptor[];
}

export type ChartDownloadType = "png" | "pdf" | "xls" | "csv";

export enum AssetErrorSeverity {
  Info = 0,
  Warning,
  Error,
  Critical
}

export interface AssetErrorDetails {
  error_code: string;
  error_severity: AssetErrorSeverity;
  stamp: string;
}

export type ValidationProps = ValidationFlags & {
  handleSubmit: (cb?: () => void) => void;
  reset: () => void;
};

export type ValidatedFormProps = ValidationProps & {
  submitting: boolean;
  locked: boolean;
};

export interface NavigationContext {
  building?: GqlBuilding | null;
  asset?: DecoratedAsset | null;
}
